// import

import type {ComponentProps, ReactNode} from 'react';

import * as T from '@radix-ui/react-tooltip';
import {TooltipProvider} from '@radix-ui/react-tooltip';

import {cram} from '@cat/rambo';

// types

type TooltipProps =
  Omit<ComponentProps<typeof T.Content>, 'children' | 'content'> & Pick<ComponentProps<typeof T.Trigger>, 'children'> & T.TooltipProps & {
    content: ReactNode;
  };

// styles

const contentCls = cram(
  'z-[100] max-w-b3 max-h-b3 text-ellipsis overflow-hidden',
  'rounded bg-white px-r6 py-r4',
  'border-2 border-pinkZ-800 shadow-hard-2 shadow-pinkZ-800',
  'text-r6',
);

const frontArrowCls = cram(
  '[&_polygon]:fill-white relative top-[-2.5px]',
);
const backArrowCls = cram(
  '[&_polygon]:fill-pinkZ-800',
);

// components

export {TooltipProvider};

export function Tooltip(props: TooltipProps) {
  const {children, defaultOpen, open, onOpenChange, content, className, delayDuration, disableHoverableContent, ...rest} = props;

  if (!content) {
    return children;
  }

  return (
    <T.Root
      defaultOpen={defaultOpen}
      delayDuration={delayDuration}
      disableHoverableContent={disableHoverableContent}
      open={open}
      onOpenChange={onOpenChange}
    >
      <T.Trigger asChild>
        {children}
      </T.Trigger>

      <T.Portal>
        <T.Content
          align="center"
          arrowPadding={16}
          side="bottom"
          sideOffset={4}
          {...rest}
          className={cram(contentCls, className)}
        >
          <T.Arrow
            className={backArrowCls}
            height={5}
            visibility="visible"
            width={11}
          />

          {content}

          <T.Arrow
            className={frontArrowCls}
            height={5}
            visibility="visible"
            width={11}
          />
        </T.Content>
      </T.Portal>
    </T.Root>
  );
}
